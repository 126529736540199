/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "highlight.js/styles/nord.css";
.container {
  @apply gap-1;
  transition:
    margin-left 0.3s ease-in-out,
    max-width 0.3s ease-in-out;
  max-width: 80rem;
}

.prose {
  --tw-prose-bullets: black;
}

main {
  padding: 1.5rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1200px) {
  body {
    padding: 1rem;
    background-color: white;
  }
  main {
    padding: 0;
  }

  .todo-name {
    overflow-wrap: break-word; /* Allows words to break to fit within the container */
    word-break: break-word; /* Ensures long words break to fit */
    white-space: normal; /* Normal white space handling */
    max-width: 100%; /* Ensures the text stays within the parent container */
  }
  .truncate-text {
    overflow-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }
  .todo-detail-button {
    padding-left: 2px;
    padding-right: 2px;
    margin-bottom: 2px;
  }
  .task-detail-button {
    margin-bottom: 2px;
  }
  .todo-detail-container {
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.line-through {
  text-decoration: line-through;
}

.prose ul {
  list-style-type: disc; /* Default bullet style */
  padding-left: 1.5rem;
}

.prose ul ul {
  list-style-type: circle; /* Second-level bullets */
  padding-left: 1.5rem;
}

.prose ul ul ul {
  list-style-type: square; /* Third-level bullets */
  padding-left: 1.5rem;
}

.custom-image-class {
  max-width: 10%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center images */
}
